import React, { useState, useEffect } from 'react';
import {
    Box as MuiBox,
    Button,
    IconButton,
    useTheme,
    Typography,
    useMediaQuery,
    Drawer,
    Toolbar,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AuditReportService from "../../service/AuditReportService";
import DataLakeService from "../../service/DataLakeService"; // Import DataLakeService
import PdfViewer from '../../components/common/PdfViewer';

const ReportPublisher = (prop) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { currentAudit } = prop;

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [gridRows, setGridRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);

    // State for the Publish Modal
    const [publishModalOpen, setPublishModalOpen] = useState(false);
    const [totalPayments, setTotalPayments] = useState('');
    const [totalFindings, setTotalFindings] = useState('');

    // State for Upload Report Dialog
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const columns = [
        { field: "rptname", headerName: "Report Name", width: 150 },
        { field: "clientname", headerName: "Client", width: 100 },
        { field: "networkname", headerName: "Network", width: 100 },
        { field: "statusname", headerName: "Status", width: 100 },
        { field: "totalPayments", headerName: "Total Payments", width: 150 },
        { field: "totalFindings", headerName: "Total Findings", width: 150 },
    ];

    const handleRowClick = (params) => {
        const selected = gridRows.find((row) => row.id === params.row.id);
        setSelectedReport(selected);
    };

    const handlePublishToggle = () => {
        if (selectedReport) {
            if (selectedReport.status === 'Open') {
                setPublishModalOpen(true); // Open modal only for publishing
            } else {
                // Directly update the report status to 'Open' without opening the modal
                const updatedReport = {
                    ...selectedReport,
                    status: 'Open',
                    rptapprovalflg: false,
                };

                const updatedRows = gridRows.map(report =>
                    report.id === updatedReport.id ? updatedReport : report
                );

                setGridRows(updatedRows);
                setSelectedReport(updatedReport);

                AuditReportService.updateReportApprovalFlg(updatedReport)
                    .then((res) => res.data)
                    .then(data => {
                        console.log(data);
                    })
                    .catch(err => console.log(err));
            }
        }
    };

    const handleSavePublish = () => {
        if (selectedReport) {
            const updatedReport = {
                ...selectedReport,
                totalPayments: totalPayments,
                totalFindings: totalFindings,
                status: selectedReport.status === 'Open' ? 'Published' : 'Open',
                rptapprovalflg: selectedReport.status === 'Open',
            };

            const updatedRows = gridRows.map(report =>
                report.id === updatedReport.id ? updatedReport : report
            );

            setGridRows(updatedRows);
            setSelectedReport(updatedReport);

            setPublishModalOpen(false);
            setTotalPayments('');
            setTotalFindings('');

            AuditReportService.updateReportApprovalFlg(updatedReport)
                .then((res) => res.data)
                .then(data => {
                    console.log(data);
                })
                .catch(err => console.log(err));
        }
    };

    const handleReviewReportClick = () => {
        setDrawerOpen(true);
    };

    const handleUploadReportClick = () => {
        setUploadDialogOpen(true);
    };

    const handleUploadFileChange = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleSaveUpload = () => {
        if (selectedReport && selectedFile) {
            const formData = new FormData();
            formData.append("auditReportId", selectedReport.auditreportkey);
            formData.append("file", selectedFile);

            console.log("Uploading report:", {
                auditReportId: selectedReport.auditreportkey,
                file: selectedFile.name,
            });

            DataLakeService.manualReportUpload(formData)
              .then((res) => {
                getAuditReport(currentAudit.audit.auditkey, function (gridData) {
                  const selected = gridData.find((row) => row.id === selectedReport.id);
                  setSelectedReport(selected);
                  setDrawerOpen(prevState => !prevState);
                });
                    console.log("Upload successful:", res.data);
                    alert("Report uploaded successfully!");
                })
                .catch(err => {
                    console.error("Upload failed:", err);
                    alert("Failed to upload report.");
                });

            setUploadDialogOpen(false);
            setSelectedFile(null);
        }
    };

    const getAuditReport = (id,callback) => {
        AuditReportService.getAuditReport(id)
            .then((res) => res.data)
            .then(data => {
              setGridRows(data);
              if (callback)
                callback(data);
            })
            .catch(err => console.log(err));
    };

    const generateReport = () => {
        AuditReportService.generateReport(selectedReport)
            .then((res) => res.data)
            .then(data => {
                if (data === true) {
                    alert("Successfully generated.");
                }
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        if (currentAudit && currentAudit.audit.auditkey) {
            getAuditReport(currentAudit.audit.auditkey);
        } else {
            getAuditReport(-1);
        }
    }, [currentAudit]);

    return (
        <MuiBox p={3} bgcolor="white">
            <Typography sx={{ mb: "15px", fontSize: "25px" }}>Report Publisher</Typography>
            <MuiBox>
                <MuiBox mt="20px">
                    <MuiBox display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h3">Audit Reports</Typography>
                        <Button onClick={() => setDrawerOpen(!drawerOpen)}>
                            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                        </Button>
                    </MuiBox>
                    <MuiBox
                        display="flex"
                        justifyContent="center"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                backgroundColor: "white",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: "white",
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: 'white',
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <MuiBox width="50%" height="600px">
                            <DataGrid
                                rows={gridRows}
                                columns={columns}
                                onRowClick={handleRowClick}
                            />
                        </MuiBox>
                        <MuiBox
                            display="flex"
                            flexDirection="column"
                            width="50%"
                            p={2}
                        >
                            {selectedReport ? (
                                <>
                                    <Typography>Report Name: {selectedReport.rptname}</Typography>
                                    <Typography>Client: {selectedReport.clientname}</Typography>
                                    <Typography>Network: {selectedReport.networkname}</Typography>
                                    <Typography>Status: {selectedReport.statusname}</Typography>
                                    <Button
                                        variant="contained"
                                        sx={{ mt: 2 }}
                                        onClick={handleReviewReportClick}
                                    >
                                        Review Report
                                    </Button>
                                    <MuiBox
                                        p={2}
                                        height="400px"
                                        mt={2}
                                    >
                                        <Typography variant="h6" gutterBottom>Report Details</Typography>
                                        <Typography>{selectedReport.details}</Typography>
                                    </MuiBox>
                                </>
                            ) : (
                                <Typography variant="h6" color="textSecondary">Select a report to see its details.</Typography>
                            )}
                        </MuiBox>
                    </MuiBox>
                </MuiBox>
            </MuiBox>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{ style: { width: '90%' } }}
            >
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <MuiBox p={2} display="flex" flexDirection="column" alignItems="center" bgcolor="white">
                    <MuiBox display="flex" width="100%">
                        <MuiBox width="40%" display="flex" flexDirection="column" p={2} bgcolor="white">
                            {selectedReport ? (
                                <>
                                    <Typography>Report Name: {selectedReport.rptname}</Typography>
                                    <Typography>Client: {selectedReport.clientname}</Typography>
                                    <Typography>Network: {selectedReport.networkname}</Typography>
                                    <Typography>Status: {selectedReport.statusname}</Typography>
                                    {
                                        selectedReport.rptapprovalflg &&
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ mt: 2 }}
                                            disabled
                                        >
                                            Published
                                        </Button>
                                    }
                                    {
                                        !selectedReport.rptapprovalflg &&
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={generateReport}
                                            sx={{ mt: 2 }}
                                        >
                                            Generate Report
                                        </Button>
                                    }
                                    <Button
                                        variant="contained"
                                        color={selectedReport.status === 'Open' ? 'primary' : 'secondary'}
                                        onClick={handlePublishToggle}
                                        sx={{ mt: 2 }}
                                    >
                                        {selectedReport.status === 'Open' ? 'Publish Report' : 'Unpublish Report'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleUploadReportClick}
                                        sx={{ mt: 2 }}
                                    >
                                        Upload Report
                                    </Button>
                                    <MuiBox
                                        p={2}
                                        height="400px"
                                        mt={2}
                                    >
                                        <Typography variant="h6" gutterBottom>Report Details</Typography>
                                        <Typography>{selectedReport.details}</Typography>
                                    </MuiBox>
                                </>
                            ) : (
                                <Typography variant="h6" color="textSecondary">Select a report to see its details.</Typography>
                            )}
                        </MuiBox>
                        <MuiBox width="60%" display="flex" flexDirection="column" alignItems="center" p={2} bgcolor="white">
                            <Typography variant="h4" color="textSecondary" sx={{ marginBottom: "20px" }}>
                                PDF Viewer
                            </Typography>
                            {
                                selectedReport && selectedReport.auditreportkey &&
                                <PdfViewer url={'/api/auditreport/download/' + selectedReport.auditreportkey}></PdfViewer>
                            }
                        </MuiBox>
                    </MuiBox>
                </MuiBox>
            </Drawer>

            {/* Upload Report Dialog */}
            <Dialog
                open={uploadDialogOpen}
                onClose={() => setUploadDialogOpen(false)}
                aria-labelledby="upload-report-dialog-title"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="upload-report-dialog-title">Upload Report</DialogTitle>
                <DialogContent>
                    <MuiBox display="flex" flexDirection="column" gap={2} mt={1}>
                        <input
                            type="file"
                            accept="application/pdf"
                            onChange={handleUploadFileChange}
                        />
                    </MuiBox>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setUploadDialogOpen(false);
                            setSelectedFile(null);
                        }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveUpload}
                        color="primary"
                        variant="contained"
                        disabled={!selectedFile}
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Publish Report Modal */}
            <Dialog
                open={publishModalOpen}
                onClose={() => setPublishModalOpen(false)}
                aria-labelledby="publish-report-dialog-title"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="publish-report-dialog-title">Publish Report</DialogTitle>
                <DialogContent>
                    <MuiBox display="flex" flexDirection="column" gap={2} mt={1}>
                        <TextField
                            label="Total Payments"
                            type="number"
                            value={totalPayments}
                            onChange={(e) => setTotalPayments(e.target.value)}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Total Findings"
                            type="number"
                            value={totalFindings}
                            onChange={(e) => setTotalFindings(e.target.value)}
                            fullWidth
                            required
                        />
                    </MuiBox>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setPublishModalOpen(false);
                        setTotalPayments('');
                        setTotalFindings('');
                    }} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSavePublish}
                        color="primary"
                        variant="contained"
                        disabled={!(totalPayments && totalFindings)}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Divider orientation="horizontal" />
        </MuiBox>
    );
};

export default ReportPublisher;
